// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-docs-template-js": () => import("./../../../src/templates/DocsTemplate.js" /* webpackChunkName: "component---src-templates-docs-template-js" */),
  "component---src-templates-help-page-template-help-page-template-js": () => import("./../../../src/templates/HelpPageTemplate/HelpPageTemplate.js" /* webpackChunkName: "component---src-templates-help-page-template-help-page-template-js" */)
}

